import React from "react"
import cx from 'classnames'

import { Link } from 'gatsby'
import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

import { handleSlug } from 'src/utils/handleSlug'


export interface TextHeroProps {
  data: {
    cols: []
  }
}

export const TwoPodColumns = ({ data }: TextHeroProps) => {
  const { cols } = data
  return (
    <div className={cx(`bb bc df fw row two__pods mb4 gutter--none`)}>
      {cols.map(({ctaLink, image, text}, i) => (
        <div className={cx('col c14 c7--800 tc', {
          'br--800 bc bb bbn--800': i === 0
        })}>
          <div className='bb p4--800 bc mt2'>
            <div className={cx('container--400 two__pods-text p2 tc tc--800 mxa rich__text')}>
              <BlockContent blocks={text} serializers={Serializer} />
            </div>
            <div className='p2 container--500 mxa pl5 pr5 pb6 pb4 pb6--800'>
              <Image imageId={image.imageId} />
            </div>
          </div>
          <Link className='button dib bn button--large founders' to={handleSlug(ctaLink[0].link._type, ctaLink[0].link.slug)}>
            <span className=''>{ctaLink[0].title}</span>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default TwoPodColumns